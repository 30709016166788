import React from 'react';
import { getAPIRoot } from '../../../helpers/fetch';
import CompanyDetailTemplate from '../../../templates/companyDetail';
import NotFoundPage from '../../404';
import { trackNotFoundEvent } from '../../../utils/application-insights';
import { API_ROUTES } from '../../../utils/constants';

const CompanyDetail = (props) => {
  const {
    serverData: { data },
  } = props;
  return data ? <CompanyDetailTemplate companyData={data} /> : <NotFoundPage />;
};

export async function getServerData(ctx) {
  const id = ctx.params.id;
  const apiRoot = await getAPIRoot();
  const locale = ctx.pageContext.locale || 'en';
  const apiUrl = `${apiRoot}${API_ROUTES.COMPANY_DETAIL}/${id}?language=${locale}`;
  let res = null;

  try {
    res = await fetch(apiUrl);

    if (!res.ok) {
      throw new Error(
        `Fetching failed. Error: ${res.status} - ${res.statusText}`
      );
    }

    if (res.status === 204) {
      return {
        status: 301,
        headers: {
          Location: `/${locale}/brands-creatives/overview/`,
        },
        props: {},
      };
    }

    const data = await res.json();
    const regexSlug = new RegExp(data.slug);
    if (ctx.url.match(regexSlug)) {
      return {
        status: 200,
        props: { data },
      };
    } else {
      return {
        status: 301,
        headers: {
          Location: `/${locale}${data.slug}`,
        },
        props: {},
      };
    }
  } catch (error) {
    trackNotFoundEvent({
      type: 'Company Detail',
      id,
      locale,
      apiRoot,
      apiUrl,
      apiStatusCode: res?.status,
    });

    return {
      status: 404,
      headers: {},
      props: {},
    };
  }
}

export default CompanyDetail;

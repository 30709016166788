import React from 'react';
import CompanyDetail from '../../components/company-detail/detail';
import Layout from '../../modules/layout';

const CompanyDetailTemplate = ({ companyData }) => {
  return (
    <Layout
      title={companyData.companyName}
      description={companyData.introductionHeadline}
      slug={companyData.slug}
    >
      <CompanyDetail data={companyData} />
    </Layout>
  );
};

export default CompanyDetailTemplate;
